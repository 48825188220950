<template>
    <!-- 店铺续费 -->
    <div class="shop-renew">
       <div class="sr-con" v-if="shop">
           <div v-if="!order_number">
                <a-form :label-col="{span:6}" :wrapper-col="{span:14}">
                    <a-form-item label="应用名称">
                        <span>{{shop.program.program_name}}</span>
                    </a-form-item>
                    <div v-if="shop.server">
                        <a-form-item label="续费版本">
                            <span>{{shop.server.server_name}}</span>
                        </a-form-item>
                        <a-form-item label="版本描述">
                            <span>{{shop.server.description}}</span>
                        </a-form-item>
                        <a-form-item label="续费价格">
                            <span>￥{{ shop.server.discount_price }}</span>
                        </a-form-item>
                        <a-form-item label="续费天数">
                            <span>{{ shop.server.expire }}天</span>
                        </a-form-item>
                    </div>
                    <a-form-item label="选择支付方式">
                        <div class="kd-paymethod">
                            <div class="kd-paymethod-li" :class="[pay_method == 2 ? 'kd-p-active' : '']" @click="pay_method = 2">
                                <i class="ri-alipay-line ri-xl ri-top" style="color: #2196F3;"></i>
                                支付宝支付
                                <img v-if="pay_method == 2" class="active-img-alipay" src="@/assets/img/icon-share-selected.png" alt="" />
                            </div>
                            <div class="kd-paymethod-li" :class="[pay_method == 1 ? 'kd-p-active' : '']" @click="pay_method = 1">
                                <i class="ri-wechat-pay-line ri-xl ri-top" style="color: #4CAF50;"></i>
                                微信支付
                                <img v-if="pay_method == 1" class="active-img" src="@/assets/img/icon-share-selected.png" alt="" />
                            </div>
                        </div>
                    </a-form-item>
                    <a-form-item :wrapper-col="{offset:6}">
                        <a-space>
                            <a-button type="primary" @click="renewNow">立即续费</a-button>
                            <a-button @click="$router.go(-1)">返回</a-button>
                        </a-space>
                    </a-form-item>
                </a-form>
            </div>
            <!-- 支付成功 -->
            <div class="kd-pay-success" v-if="order_number">
                <div class="kd-pay-success-top">
                    <div class="pay-success-icon"><img src="@/assets/img/gou.png" alt="" /></div>
                </div>
                <div class="kd-pay-success-title">
                    您的订单{{ order_number }}已经支付成功，<span>{{time}}</span> 秒后将自动跳转到店铺列表~
                </div>
            </div>
        </div>  

        <a-modal v-model:visible="show.wxpay" title="微信支付" @cancel="show.wxpay = false" :footer="null">
			<div class="wx-pay-dialog-content">
				<div class="wx-pay-dialog-qrcode">
					<div class="qrcode-img">
						<img :src="wx_pay_qrcode" alt="">
					</div>
				</div>
				<div class="wx-pay-dialog-total" v-if="order">
                    支付金额：{{order.total_price ?order.total_price :shop.server.discount_price}}元
                </div>
				<div class="wx-pay-dialog-tag">请使用微信扫一扫，扫描二维码进行支付</div>
			</div>
			<div class="wx-pay-dialog-tag-1">支付完成前请不要关闭此窗口</div>
			<div style="text-align: center;">
				<a-space>
					<a-button @click="show.wxpay = false">不想支付了</a-button>
					<a-button type="primary" @click="checkMyPayd">我已支付</a-button>
				</a-space>
			</div>
		</a-modal>
    </div>
</template>
<script>
import { reactive, toRefs } from 'vue'
import appModel from '@/api/saas/app.js'
import { useRoute } from 'vue-router'
import router from '@/router'
export default {
    setup() {
        const _d = reactive({
            shop:null,  //店铺信息
            pay_method:2,
            order:null,
            show:{
                wxpay:false,
            },
            wx_pay_qrcode:"",   //微信支付二维码
            order_number:"",
            time:5
        })
        
        const options = useRoute().query
        if( options.id ){
            appModel.getShopPreOrder(options.id,'program_order',res=>{
                _d.shop = res
            })
        }
        if( options.out_trade_no ){
            _d.order_number = options.out_trade_no
            //支付成功，自动跳转
           successJump()
        }

		function successJump() {
			 let timer = setInterval(() => {
                if( _d.time >0 ){
                    _d.time --
                }else{
                    clearInterval(timer)
                    router.push("/shop/index")
                }
            }, 1000);
		}
    
        //创建续费订单
        function renewNow(){
            appModel.createShopOrder(options.id,_d.pay_method,"program_order",res=>{
                _d.order = res

                appModel.shopOrderPay(res.order_id,'program_order',_d.pay_method,res=>{
                    _d.show.wxpay = true;
					_d.wx_pay_qrcode = res.qr_code;
                })
            })
        }

        function checkMyPayd() {
			// _d.order.order_number = "T2021040109285257374"
			if( !_d.order.order_number ){
				appModel.getAppOrder(1,9,null,res=>{
					res.list.forEach(item => {
						if( item.id == _d.order.order_id){
							_d.order.order_number = item.order_number

							appModel.checkIsPay(_d.order.order_number,()=>{
								_d.order_number = order.order_number;
								_d.show.wxpay = false;
							})
						}
					});
				})
			}else{
				appModel.checkIsPay(_d.order.order_number,()=>{
					_d.order_number = _d.order.order_number;
					_d.show.wxpay = false;
					successJump()
				})
			}
		}

        return{
            ...toRefs(_d),
            renewNow,
            checkMyPayd
        }
    },
}
</script>
<style lang="scss" scoped>
.sr-con{
    background: #fff;
    margin-top: 12px;
    padding: 24px;
}

.kd-paymethod {
	display: flex;

	&-li {
		width: 150px;
		height: 50px;
		border: 1px solid #e4e4e4;
		margin-right: 10px;
		text-align: center;
		font-size: 18px;
		cursor: pointer;

		.iconfont {
			font-size: 22px;
			margin-right: 5px;
		}

		.active-img {
			position: absolute;
			width: 24px;
			height: 20px;
			margin-top: 28px;
			margin-left: -1px;
		}
		.active-img-alipay {
			position: absolute;
			width: 24px;
			height: 20px;
			margin-top: 28px;
			margin-left: -11px;
		}
	}

	.kd-p-active {
		border: 2px solid #ff4544;
	}
}

.wx-pay-dialog {
	&-content {
		text-align: center;
		border-bottom: 1px dashed #e6e6e6;
		padding-bottom: 20px;
	}
	&-qrcode {
		width: 100%;
		height: 200px;
		padding: 10px;
		display: flex;
		justify-content: center;

		.qrcode-img {
			width: 160px;
			height: 160px;
			box-shadow: 1px 2px 10px #bdd8f1;
		}
		img {
			width: 150px;
			height: 150px;
		}
	}
	&-total {
		color: orange;
		font-size: 16px;
		font-weight: bold;
		margin-top: 20px;
	}
	&-tag {
		font-size: 12px;
		color: #868686;
		margin-top: 10px;
	}
	&-tag-1 {
		width: 100%;
		text-align: center;
		font-size: 14px;
		color: #868686;
		height: 50px;
		line-height: 50px;
	}
}

.kd-pay-success {
	width: 100%;
	height: 300px;
	text-align: center;
	padding-top: 40px;

	&-top {
		width: 100%;
		display: flex;
		justify-content: center;
		.pay-success-icon {
			width: 50px;
			height: 50px;
			border-radius: 50%;
			background: #4caf50;
			color: #ffffff;
			line-height: 50px;
		}

		img {
			width: 30px;
			height: 30px;
			margin-top: 7px;
		}
	}
	&-title {
		color: #868686;
		margin: 10px 0 20px 0;

        span{
            font-size: 18px;
            color: #333;
        }
	}
}

</style>